<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>

      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="人均可支配收入情况"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <div style="position: relative;height: 500px;">
      <el-table
        v-loading="loading"
        ref="table"
        :data="listData"
        style="width:100%;overflow-x: auto;position: absolute;"
        height="500px"
        :span-method="objectSpanMethod"
      >
        <el-table-column fixed type="index" label="序号" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column fixed align="center" prop="countyId" width="140" label="县（市区）">
          <template slot-scope="scope">
            <div>
              <el-cascader  :append-to-body="false"  
                v-model="scope.row.countyId"
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                :options="levalRegion"
                :props="treeProps"
                @change="handleChange($event, scope.$index)"
                :show-all-levels="false"
                placeholder="请选择"
              >
              </el-cascader>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="农村居民人均可支配收入（元、%）">
          <el-table-column width="160" align="center" prop="residentPerCapitaIncomeBefore" :label="title2">
            <template slot-scope="scope">
              <el-input
                v-if="type == 'add' || type == 'edit'"
                size="mini"
                type="number"
                min="0"
                v-model="scope.row.residentPerCapitaIncomeBefore"
                placeholder="请输入"
              >
              </el-input>
              <span v-else>
                {{ formatNumericWithTwoDecimalPlaces(scope.row.residentPerCapitaIncomeBefore) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" prop="residentPerCapitaIncome" :label="title1">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-if="type == 'add' || type == 'edit'"
                type="number"
                min="0"
                v-model="scope.row.residentPerCapitaIncome"
                placeholder="请输入"
              >
              </el-input>

              <span v-else>
                {{ formatNumericWithTwoDecimalPlaces(scope.row.residentPerCapitaIncome) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" prop="residentYearOnYearIncrease" :label="title3">
            <template slot-scope="scope">
              <div>
                {{
                  formatNumericWithTwoDecimalPlaces(
                    formatRateIncrease2('residentPerCapitaIncome', 'residentPerCapitaIncomeBefore', scope.$index)
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" align="center" prop="residentIncreaseRate" label="增长率">
            <template slot-scope="scope">
              <div>
                {{ formatRate('residentPerCapitaIncome', 'residentPerCapitaIncomeBefore', scope.$index) }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column align="center" label="农村移民人均可支配收入（元、%）">
          <el-table-column width="160" align="center" prop="immigrantPerCapitaIncomeBefore" :label="title2">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-if="type == 'add' || type == 'edit'"
                type="number"
                min="0"
                v-model="scope.row.immigrantPerCapitaIncomeBefore"
                placeholder="请输入"
              >
              </el-input>

              <span v-else>
                {{ formatNumericWithTwoDecimalPlaces(scope.row.immigrantPerCapitaIncomeBefore) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" prop="immigrantPerCapitaIncome" :label="title1">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-if="type == 'add' || type == 'edit'"
                type="number"
                min="0"
                v-model="scope.row.immigrantPerCapitaIncome"
                placeholder="请输入"
              >
              </el-input>

              <span v-else>
                {{ formatNumericWithTwoDecimalPlaces(scope.row.immigrantPerCapitaIncome) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" prop="immigrantYearOnYearIncrease" :label="title3">
            <template slot-scope="scope">
              <div>
                {{
                  formatNumericWithTwoDecimalPlaces(
                    formatRateIncrease2('immigrantPerCapitaIncome', 'immigrantPerCapitaIncomeBefore', scope.$index)
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" align="center" prop="immigrantIncreaseRate" label="增长率">
            <template slot-scope="scope">
              <div>
                {{ formatRate('immigrantPerCapitaIncome', 'immigrantPerCapitaIncomeBefore', scope.$index) }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column align="center" label="移民与农村居民人均可支配收入差距">
          <el-table-column width="160" align="center" prop="incomeDisparityBefore" :label="title2">
            <template slot-scope="scope">
              <div>
                {{
                  formatNumericWithTwoDecimalPlaces(
                    formatRateIncrease('immigrantPerCapitaIncomeBefore', 'residentPerCapitaIncomeBefore', scope.$index)
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" prop="incomeDisparity" :label="title1">
            <template slot-scope="scope">
              <div>
                {{
                  formatNumericWithTwoDecimalPlaces(
                    formatRateIncrease('immigrantPerCapitaIncome', 'residentPerCapitaIncome', scope.$index)
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" prop="incomeDisparityIncrease" :label="title3">
            <template slot-scope="scope">
              <div>
                {{ formatNumericWithTwoDecimalPlaces(formatRateIncrease1(scope.$index)) }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column fixed="right" align="center" prop="name" width="80" label="操作" v-if="type == 'add'">
          <template slot-scope="scope">
            <el-link type="danger" @click="removeCol(scope.$index)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import { _getLevelRegion } from '@/api/keepHome'

import {
  _DisposableIncomeAdd,
  _DisposableIncomeInfo,
  _DisposableIncomeEdit
} from '@/api/modular/postImmigrationService/monitorAndEvaluate/disposableIncome.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      loading: false,
      options: [],
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      levalRegion: [],
      title1: '',
      title2: '',
      title3: ''
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 300 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
      this.listData[index].orderNo = obj.orderNo
    },
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    calculateTheTotal(name) {
      // console.log(name,"======calculateTheTotal=======");
      let sum = 0
      if (name == 'residentYearOnYearIncrease') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            (Number(ele['residentPerCapitaIncome']) * 100 - Number(ele['residentPerCapitaIncomeBefore']) * 100) / 100
        }
      } else if (name == 'residentIncreaseRate') {
        // 定义一个变量保存上一次的sum值
        let prevSum = 0
        // 遍历listData数组
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          // 当前sum值计算
          let currentSum =
            ((Number(ele['residentPerCapitaIncome']) - Number(ele['residentPerCapitaIncomeBefore'])) /
              Number(ele['residentPerCapitaIncomeBefore'])) *
            100

          // 如果当前sum值为NaN或Infinity，则使用上一次的sum值
          if (Number.isNaN(currentSum) || isFinite(currentSum)) {
            sum += Number.isNaN(currentSum) ? 0 : currentSum
          } else {
            sum = prevSum
          }
          // 更新上一次的sum值
          prevSum = sum
        }

        return prevSum.toFixed(2)
      } else if (name == 'immigrantYearOnYearIncrease') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            (Number(ele['immigrantPerCapitaIncome']) * 100 - Number(ele['immigrantPerCapitaIncomeBefore']) * 100) / 100
        }
      } else if (name == 'immigrantIncreaseRate') {
        // 定义一个变量保存上一次的sum值
        let prevSum = 0
        // 遍历listData数组
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          // 当前sum值计算
          let currentSum =
            ((Number(ele['immigrantPerCapitaIncome']) - Number(ele['immigrantPerCapitaIncomeBefore'])) /
              Number(ele['immigrantPerCapitaIncomeBefore'])) *
            100
          // 如果当前sum值为NaN或Infinity，则使用上一次的sum值
          if (Number.isNaN(currentSum) || isFinite(currentSum)) {
            sum += Number.isNaN(currentSum) ? 0 : currentSum
          } else {
            sum = prevSum
          }
          // 更新上一次的sum值
          prevSum = sum
        }
        return prevSum.toFixed(2)
      } else if (name == 'incomeDisparityBefore') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            Number(ele['immigrantPerCapitaIncomeBefore']) * 100 - Number(ele['residentPerCapitaIncomeBefore']) * 100
        }
      } else if (name == 'incomeDisparity') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += Number(ele['immigrantPerCapitaIncome']) - Number(ele['residentPerCapitaIncome'])
        }
      } else if (name == 'incomeDisparityIncrease') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            Number(ele['immigrantPerCapitaIncome']) -
            Number(ele['residentPerCapitaIncome']) -
            (Number(ele['immigrantPerCapitaIncomeBefore']) - Number(ele['residentPerCapitaIncomeBefore']))
        }
      } else {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += Number(ele[name])
        }
      }
      return sum
    },

    formatRateIncrease(name1, name2, index) {
      let sum = 0
      let ele = this.listData[index]
      sum += Number(ele[name2]) - Number(ele[name1])
      return sum
    },

    formatRateIncrease2(name1, name2, index) {
      let sum = 0
      let ele = this.listData[index]
      sum += Number(ele[name1]) - Number(ele[name2])
      return sum
    },

    formatRateIncrease1(index) {
      let sum = 0
      let ele = this.listData[index]
      sum +=
        Number(ele['residentPerCapitaIncome']) -
        Number(ele['immigrantPerCapitaIncome']) -
        (Number(ele['residentPerCapitaIncomeBefore']) - Number(ele['immigrantPerCapitaIncomeBefore']))
      return sum
    },
    formatRate(name1, name2, index) {
      let sum = 0
      // 定义一个变量保存上一次的sum值
      let prevSum = 0
      // 遍历listData数组

      let ele = this.listData[index]
      // 当前sum值计算
      let currentSum = ((Number(ele[name1]) - Number(ele[name2])) / Number(ele[name2])) * 100
      // 如果当前sum值为NaN或Infinity，则使用上一次的sum值
      if (Number.isNaN(currentSum) || isFinite(currentSum)) {
        sum = Number.isNaN(currentSum) ? 0 : currentSum
      } else {
        sum = prevSum
      }
      // 更新上一次的sum值
      prevSum = sum

      return Math.round((prevSum.toFixed(4) * 100) / 100) + '%'
    },
    refreshYear(year) {
      this.searchObj.year = year
      this.title1 = `${this.searchObj.year}年`
      this.title2 = `${this.searchObj.year - 1}年`
      this.title3 = `${this.searchObj.year}年比${this.searchObj.year - 1}年增加`
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row

      this.title1 = `${row.year}年`
      this.title2 = `${row.year - 1}年`
      this.title3 = `${row.year}年比${row.year - 1}年增加`

      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    btnClose() {
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null, //县（市区）
            county: '', //县（市区）
            residentPerCapitaIncomeBefore: null,
            residentPerCapitaIncome: null,
            residentYearOnYearIncrease: null,
            residentIncreaseRate: null,

            immigrantPerCapitaIncomeBefore: null,
            immigrantPerCapitaIncome: null,
            immigrantYearOnYearIncrease: null,
            immigrantIncreaseRate: null,

            incomeDisparityBefore: null,
            incomeDisparity: null,
            incomeDisparityIncrease: null,

            countyPids: '', //pids
            orderNo: 0
          }

          // if (this.listData.length) {
          //   let flag = this.listData.every(ele => ele.countyId)
          //   if (flag) {
          //     this.listData.splice(this.listData.length, 0, obj)
          //   } else {
          //     this.$message.error('请选择县（市区）')
          //   }
          // } else {
          //   this.listData.splice(this.listData.length, 0, obj)
          // }

          this.listData.splice(this.listData.length, 0, obj)
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getBasicInfo()
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }
      _DisposableIncomeInfo(params).then(res => {
        if (res.code == 200) {
          this.listData = [res.data]
          // let obj = {
          //   year: this.searchObj.year, //年份
          //   countyId: null, //县（市区）
          //   county: '总计', //县（市区）
          //   residentPerCapitaIncomeBefore: 0,
          //   residentPerCapitaIncome: 0,
          //   residentYearOnYearIncrease: 0,
          //   residentIncreaseRate: 0,

          //   immigrantPerCapitaIncomeBefore: 0,
          //   immigrantPerCapitaIncome: 0,
          //   immigrantYearOnYearIncrease: 0,
          //   immigrantIncreaseRate: 0,

          //   incomeDisparityBefore: 0,
          //   incomeDisparity: 0,
          //   incomeDisparityIncrease: 0,

          //   countyPids: '', //pids
          //   orderNo: 0
          // }

          // this.listData.unshift(obj)
          console.log(this.listData, '========获取详情==========')
        }
      })
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {},
    submitForm() {
      console.log(this.listData, '===submitForm===')
      let flag = this.listData.every(ele => {
        console.log(ele, '==ele===')
        return ele.countyId
      })

      console.log(flag, '=====flag===')
      switch (this.type) {
        case 'add':
          if (flag) {
            _DisposableIncomeAdd(this.listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
        case 'edit':
          if (flag) {
            _DisposableIncomeEdit(this.listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/elementui.scss';
.small_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.import_opration {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

::v-deep tbody tr.el-table__row:nth-child(1) {
  height: 49px;
  border: 0 !important;
  background-color: #fff !important;
  position: relative !important;
  top: 0;
  left: 0;
  z-index: 1;
  td {
    border-top: 0 !important;
    border-bottom: 1px solid #f5f7fa !important;
  }
  td:first-child {
    border-left: 0 !important;
  }
  td:last-child {
    border-right: 1px solid #f5f7fa !important;
  }
}
</style>
